'use client';

import { useAtom } from 'jotai';
import fetchShows, { FetchShowsArgs } from '@/lib/content-services/queries/shows';
import { showsFiltersAtom } from '@/lib/atoms/shows-filters';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { FilterObject } from '@/lib/types/showsFilters';
import { GraphQLPlatformType } from '@/lib/content-services/graphql';
import usePrevious from '@/lib/hooks/usePrevious';
import useDidMount from '@/lib/hooks/useDidMount';
import { objectsMatchShallow } from '@/lib/helpers/equality';
import { constructFilteredURL } from '../helpers';
import ReactFetchAbortError from '@/lib/errors/ReactFetchAbortError';
import ShowPosterGrid from '@/components/ShowPosterGrid/ShowPosterGrid';
import LoadingIndicator from '@/components/LoadingIndicator/LoadingIndicator';
import styles from './ShowsLandingResults.module.scss';
import useInfiniteScroll from '@/lib/hooks/useInfiniteScroll';
import { SearchShowsResponse, ShowNode } from '@/lib/types/graphql/searchShowsResponse';
import { getStationId, getUserId } from '@/lib/profile';
interface SearchShowsConfig {
  append?: boolean;
  abortController?: AbortController;
}
interface ShowsData {
  data: SearchShowsResponse;
}
const memo: Record<string, ShowsData> = {};
async function memoizedFetchShows(args: FetchShowsArgs, abortController?: AbortController) {
  const key = JSON.stringify(args);
  if (memo[key]) {
    return memo[key];
  }
  const results = await fetchShows(args, abortController);
  memo[key] = results;
  return results;
}
function hydrateMemo(args: FetchShowsArgs, shows: ShowsData) {
  const key = JSON.stringify(args);
  memo[key] = shows;
}
interface ShowsLandingResultsProps {
  initialServerSideResults: {
    shows: ShowNode[];
    hasNextPage: boolean;
    cursor: string | null;
    withFilters: FilterObject;
  };
  memoHydration: ShowsData;
}
function ShowsLandingResults({
  initialServerSideResults,
  memoHydration
}: ShowsLandingResultsProps) {
  const [showsFilters] = useAtom(showsFiltersAtom);
  const previousFilters = usePrevious(showsFilters);
  const onMount = useDidMount();
  const router = useRouter();
  const [infiniteScrollingEnabled, setInfiniteScrollingEnabled] = useState(true);
  const scrollingResultsRef = useRef(null);
  const onInfiniteScroll = useInfiniteScroll(scrollingResultsRef);
  const [shows, setShows] = useState(initialServerSideResults.shows);
  const [cursor, setCursor] = useState<string | null>(initialServerSideResults.cursor);
  const [hasNextPage, setHasNextPage] = useState<boolean>(initialServerSideResults.hasNextPage);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const searchShows = useCallback(async function (filters: FilterObject, config: SearchShowsConfig) {
    const options: FetchShowsArgs = {
      platform: window.pbsPlatform as GraphQLPlatformType,
      filters: filters,
      stationId: getStationId(),
      userId: getUserId()
    };
    if (cursor && config.append) options.after = cursor;
    try {
      if (config.append) {
        setIsLoadingMore(true);
      } else {
        setIsLoading(true);
      }
      const results = await memoizedFetchShows(options, config.abortController);
      const shows = results.data.searchShows.edges.map(edge => edge.node);
      const nextPage = results.data.searchShows.pageInfo.hasNextPage;
      setHasNextPage(nextPage);
      if (nextPage) {
        setCursor(results.data.searchShows.pageInfo.endCursor);
      } else {
        setCursor(null);
      }
      if (config.append) {
        setShows(previousShows => [...previousShows, ...shows]);
      } else {
        setShows(shows);
      }
      if (config.append) {
        setIsLoadingMore(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (error instanceof ReactFetchAbortError) {
        console.log('Midflight request aborted. Likely filter update while calling CS');
      }
    }
  }, [cursor]);
  const handleFilterUpdates = useCallback((filters: FilterObject, abortController: AbortController) => {
    const searchUrl = constructFilteredURL(filters);
    router.push(searchUrl);
    searchShows(filters, {
      abortController
    });
  }, [router, searchShows]);
  useEffect(() => {
    if (previousFilters && !objectsMatchShallow(showsFilters, previousFilters)) {
      const abortController = new AbortController();
      handleFilterUpdates(showsFilters, abortController);
      return () => {
        abortController.abort(new ReactFetchAbortError('Call was superceeded or component unmounted'));
      };
    }
    /*
     * We are intentially ommitting handleFilterUpdates here to prevent unnecessary rerenders
     * - Adding handleFilterUpdates to the dependency array causes the useEffect to re-run unnecessarily,
     *   which leads to the premature firing of AbortController, in turn canceling in-flight requests before they complete.
     * - Currently, handleFilterUpdates only runs when searchFilters change, which prevents redundant network calls.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showsFilters]);
  onMount(() => {
    const options: FetchShowsArgs = {
      platform: window.pbsPlatform as GraphQLPlatformType,
      filters: showsFilters
    };
    hydrateMemo(options, memoHydration);
  });
  function loadMore() {
    searchShows(showsFilters, {
      append: true
    });
  }
  onInfiniteScroll(() => {
    if (!isLoadingMore && hasNextPage && infiniteScrollingEnabled) {
      loadMore();
    }
  });
  if (isLoading) {
    return <div className={styles.results}>
        <LoadingIndicator className={styles.loading_indicator} />
      </div>;
  } else if (shows.length === 0) {
    return <div className={styles.results}>
        <h2 className={styles.results_text_header}>
          There are no matching results for this search.
        </h2>

        <span className={styles.results_text_small}>Try using a different keyword.</span>
      </div>;
  }
  return <div className={styles.results} ref={scrollingResultsRef} data-sentry-component="ShowsLandingResults" data-sentry-source-file="ShowsLandingResults.tsx">
      <ShowPosterGrid shows={shows} data-sentry-element="ShowPosterGrid" data-sentry-source-file="ShowsLandingResults.tsx" />

      {isLoadingMore && <LoadingIndicator className={styles.loading_indicator} />}

      {hasNextPage && !isLoadingMore && !infiniteScrollingEnabled && <button className={styles.load_more_button} onClick={loadMore}>Load More</button>}
    </div>;
}
export default ShowsLandingResults;