'use client';

import { useState } from 'react';
import { useAtom } from 'jotai';
import { showsFiltersAtom } from '@/lib/atoms/shows-filters';
import useWindowScroll from '@/lib/hooks/useWindowScroll';
import { GENRES, SOURCE_FILTERS, SORT_BY, ALL_POSSIBLE_VALUES } from '@/lib/types/graphql/searchShows';
import useThrottledCallback from '@/lib/hooks/useThrottledCallback';
import styles from '@/app/(main)/shows/ShowsLandingFilters/DesktopFilters.module.scss';
import { FilterObject } from '@/app/(main)/shows/helpers';
export interface DesktopFiltersProps {
  station?: {
    id?: string;
    commonName?: string;
  };
}
function DesktopFilters(props: DesktopFiltersProps) {
  const onWindowScroll = useWindowScroll();
  const [showsFilters, setShowsFilters] = useAtom(showsFiltersAtom);
  const [showBackToTop, setShowBackToTop] = useState(false);
  function updateFilter(key: keyof FilterObject, value: ALL_POSSIBLE_VALUES) {
    setShowsFilters(previousFilters => {
      return {
        ...previousFilters,
        [key]: value
      };
    });
  }
  onWindowScroll(useThrottledCallback(event => {
    window.scrollY > 350 ? setShowBackToTop(true) : setShowBackToTop(false);
  }));
  function scrollToTop() {
    setShowBackToTop(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  ;
  function getLabel(str: string) {
    if (str === 'Local Shows' && props.station?.commonName) {
      return `Only ${props.station.commonName} Shows`;
    }
    return str;
  }
  return <form className={styles.form} action='#' onSubmit={e => e.preventDefault()} data-sentry-component="DesktopFilters" data-sentry-source-file="DesktopFilters.tsx">
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>
          {props.station?.id ? 'Local & ' : ''}
          Featured in Passport:
        </legend>

        {SOURCE_FILTERS.map((source, i) => <span className={styles.radio} key={i}>
            <input type='radio' name='filter' value={source.value} aria-checked={showsFilters.source === source.value} checked={showsFilters.source === source.value} onChange={() => updateFilter('source', source.value)} />
            <label className={styles.radio_label} htmlFor={source.value} onClick={() => updateFilter('source', source.value)}>{getLabel(source.label)}</label>
        </span>)}
      </fieldset>

      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>Genre</legend>

        {GENRES.map((genre, i) => <span className={styles.radio} key={i}>
            <input type='radio' name='genre' value={genre.value} aria-checked={showsFilters.genre === genre.value} checked={showsFilters.genre === genre.value} onChange={() => updateFilter('genre', genre.value)} />
            <label className={styles.radio_label} htmlFor={genre.value} onClick={() => updateFilter('genre', genre.value)}>{genre.label}</label>
          </span>)}
      </fieldset>

      <fieldset className={styles.sort_by}>
        <legend>Sort By:</legend>
        {SORT_BY.map((sortBy, i) => <span key={i}>
            <input type='radio' name='sort' value={sortBy.value} checked={showsFilters.sortBy === sortBy.value} onChange={() => updateFilter('sortBy', sortBy.value)} />
            <label htmlFor={sortBy.value} onClick={() => updateFilter('sortBy', sortBy.value)}>{sortBy.label}</label>
          </span>)}
      </fieldset>

      <button className={`${styles.scroll_to_top_button} ${showBackToTop ? '' : styles.hidden}`} onClick={scrollToTop}>
        Back to Top
      </button>
    </form>;
}
export default DesktopFilters;