'use client';

import { useHydrateAtoms } from "jotai/utils";
import { FilterObject, produceFiltersFromURL } from '@/app/(main)/shows/helpers';
import { showsFiltersAtom } from '@/lib/atoms/shows-filters';
import { useSearchParams } from 'next/navigation';
import { objectsMatchShallow } from '@/lib/helpers/equality';
import { useAtom } from "jotai";
import { useEffect } from "react";
function FilterAtomsHydrator({
  initialServerSideFilters
}: {
  initialServerSideFilters: FilterObject;
}) {
  useHydrateAtoms([[showsFiltersAtom, initialServerSideFilters]]);
  const [showsFilters, setShowsFilters] = useAtom(showsFiltersAtom);
  const params = useSearchParams();
  useEffect(() => {
    if (params.size === 0) return;
    const filtersFromURL = produceFiltersFromURL(params);
    if (objectsMatchShallow(filtersFromURL, showsFilters)) {} else {
      setShowsFilters(filtersFromURL);
    }
    // Only update if params changes, but not if showsFilters or setShowsFilters updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  useEffect(() => {
    if (!objectsMatchShallow(showsFilters, initialServerSideFilters)) {
      // filters do not match initial rendering, so it's actually time to update
      // triggering a graphql call
      setShowsFilters(initialServerSideFilters);
    }
    // deliberately only running this after the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
export default FilterAtomsHydrator;